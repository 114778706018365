import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"testimonials"} />
		<Helmet>
			<title>
				Відгуки | Fusion Tavern Відгуки про таверну Fusion
			</title>
			<meta name={"description"} content={"Де кожен відвідувач пише захоплений відгук"} />
			<meta property={"og:title"} content={"Відгуки | Fusion Tavern Відгуки про таверну Fusion"} />
			<meta property={"og:description"} content={"Де кожен відвідувач пише захоплений відгук"} />
			<meta property={"og:image"} content={"https://virolosist.live/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://virolosist.live/media/281620.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://virolosist.live/media/281620.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://virolosist.live/media/281620.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://virolosist.live/media/281620.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://virolosist.live/media/281620.png"} />
			<meta name={"msapplication-TileImage"} content={"https://virolosist.live/media/281620.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Розповіді гостей
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px" text-align="center">
				Fusion - це не просто місце, це історія, яку переживають усі, хто заходить у його двері.{" "}
				<br />
				Ось що пишуть деякі з наших щасливих відвідувачів:
			</Text>
		</Section>
		<Section
			padding="96px 0 24px 0"
			xl-padding="80px 0 20px 0"
			lg-padding="64px 0 16px 0"
			md-padding="48px 0 8px 0"
			sm-padding="36px 0 0px 0"
			quarkly-title="Reviews"
		>
			<Override slot="SectionContent" max-width="800px" />
			<Box
				quarkly-title="Box"
				margin="0px 0px 80px 0px"
				xl-margin="0px 0px 64px 0px"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 36px 0px"
				sm-margin="0px 0px 24px 0px"
			>
				<Text
					font="--headline3"
					color="--dark"
					letter-spacing=".02em"
					lg-font="normal 400 24px/1.3 &quot;Libre Caslon Display&quot;, serif"
					xl-font="normal 400 30px/1.3 &quot;Libre Caslon Display&quot;, serif"
					md-font="normal 400 20px/1.3 &quot;Libre Caslon Display&quot;, serif"
					margin="0px 0px 32px 0px"
					xl-margin="0px 0px 24px 0px"
					lg-margin="0px 0px 20px 0px"
					md-margin="0px 0px 16px 0px"
					sm-margin="0px 0px 8px 0px"
				>
					"Мій перший візит до "Свистка та Бочки" був незабутнім. Крильця, настояні на елі, були справжнім відкриттям, а атмосфера - саме те, що мені було потрібно після довгого тижня. Це моє нове улюблене місце для відпочинку".
				</Text>
				<Text font="normal 400 20px/1.8 --fontFamily-googleLibreCaslonDisplay" letter-spacing=".02em" color="--dark" margin="0px 0px 0px 0px">
					– Олег Удовенко
				</Text>
			</Box>
			<Box
				quarkly-title="Box"
				margin="0px 0px 80px 0px"
				xl-margin="0px 0px 64px 0px"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 36px 0px"
				sm-margin="0px 0px 24px 0px"
			>
				<Text
					font="--headline3"
					color="--dark"
					letter-spacing=".02em"
					lg-font="normal 400 24px/1.3 &quot;Libre Caslon Display&quot;, serif"
					xl-font="normal 400 30px/1.3 &quot;Libre Caslon Display&quot;, serif"
					md-font="normal 400 20px/1.3 &quot;Libre Caslon Display&quot;, serif"
					margin="0px 0px 32px 0px"
					xl-margin="0px 0px 24px 0px"
					lg-margin="0px 0px 20px 0px"
					md-margin="0px 0px 16px 0px"
					sm-margin="0px 0px 8px 0px"
				>
					"Мені подобається, як Fusion поєднує затишну обстановку з яскравою атмосферою. За бардівський бургер можна вмерти, а вечори живої музики - ідеальне тло для чудового вечора з друзями".
				</Text>
				<Text font="normal 400 20px/1.8 --fontFamily-googleLibreCaslonDisplay" letter-spacing=".02em" color="--dark" margin="0px 0px 0px 0px">
					– Олена Авраменко
				</Text>
			</Box>
			<Box
				quarkly-title="Box"
				margin="0px 0px 80px 0px"
				xl-margin="0px 0px 64px 0px"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 36px 0px"
				sm-margin="0px 0px 24px 0px"
			>
				<Text
					font="--headline3"
					color="--dark"
					letter-spacing=".02em"
					lg-font="normal 400 24px/1.3 &quot;Libre Caslon Display&quot;, serif"
					xl-font="normal 400 30px/1.3 &quot;Libre Caslon Display&quot;, serif"
					md-font="normal 400 20px/1.3 &quot;Libre Caslon Display&quot;, serif"
					margin="0px 0px 32px 0px"
					xl-margin="0px 0px 24px 0px"
					lg-margin="0px 0px 20px 0px"
					md-margin="0px 0px 16px 0px"
					sm-margin="0px 0px 8px 0px"
				>
					"Від містичних делікатесів до добірки пива від пивовара - кожен візит до Fusion - це кулінарна пригода. Персонал змушує відчувати себе як вдома, а куточок для розмов - це така унікальна родзинка!"
				</Text>
				<Text font="normal 400 20px/1.8 --fontFamily-googleLibreCaslonDisplay" letter-spacing=".02em" color="--dark" margin="0px 0px 0px 0px">
					– Юрій Бондаренко
				</Text>
			</Box>
			<Box
				quarkly-title="Box"
				margin="0px 0px 80px 0px"
				xl-margin="0px 0px 64px 0px"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 36px 0px"
				sm-margin="0px 0px 24px 0px"
			>
				<Text
					font="--headline3"
					color="--dark"
					letter-spacing=".02em"
					lg-font="normal 400 24px/1.3 &quot;Libre Caslon Display&quot;, serif"
					xl-font="normal 400 30px/1.3 &quot;Libre Caslon Display&quot;, serif"
					md-font="normal 400 20px/1.3 &quot;Libre Caslon Display&quot;, serif"
					margin="0px 0px 32px 0px"
					xl-margin="0px 0px 24px 0px"
					lg-margin="0px 0px 20px 0px"
					md-margin="0px 0px 16px 0px"
					sm-margin="0px 0px 8px 0px"
				>
					"Я був у багатьох пабах, але Fusion вирізняється ідеальним поєднанням традицій та сучасного стилю. Вегетаріанська страва Druid's Delight Veggie Wrap стала приємним сюрпризом, а вибір віскі вражає".
				</Text>
				<Text font="normal 400 20px/1.8 --fontFamily-googleLibreCaslonDisplay" letter-spacing=".02em" color="--dark" margin="0px 0px 0px 0px">
					– Ігор Кравченко
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});